import React from "react";
import { Trans, useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Section } from "../Section";
import { Card } from "../Card";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { CurrencyFormatter } from "../../hooks/formatter/CurrencyFormatter/CurrencyFormatter";
import { FieldErrors } from "react-hook-form";
import { Ownership, SusaMetadata } from "../../Store/api";
import { Ownerships } from "../Ownerships/Ownerships";

export interface AdditionalCompanyInformationProps {
  susa: SusaMetadata;
  handleSusaDataChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => void;
  handleOwnershipChange: (newOwnerships: Ownership[]) => void;
  formErrors?: FieldErrors;
  detailsCompany?: any;
}
export const AdditionalCompanyInformation = React.forwardRef(
  (
    {
      detailsCompany,
      susa,
      handleSusaDataChange,
      handleOwnershipChange,
      formErrors,
    }: AdditionalCompanyInformationProps,
    ref: React.ForwardedRef<unknown>
  ) => {
    const {
      data: {
        sales_prev_year,
        sales_last_year,
        ebitda_prev_year,
        ebitda_last_year,
        ebit_prev_year,
        ebit_last_year,
        number_employees_prev_year,
        number_employees_last_year,
        ownerships,
      },
    } = susa;
    const { t } = useTranslation(["company/common"]);
    const last_year = dayjs().year() - 1;
    const prev_year = dayjs().year() - 2;

    const vale_prev_year = detailsCompany?.company?.turnovers?.find(
      (turnover: any) => turnover.year === prev_year
    )?.value;
    const vale_last_year = detailsCompany?.company?.turnovers?.find(
      (turnover: any) => turnover.year === last_year
    )?.value;
    const value_employees_prev_year = detailsCompany?.company?.employees?.find(
      (employee: any) => employee.year === prev_year
    )?.numberOfEmployees;
    const value_employees_last_year = detailsCompany?.company?.employees?.find(
      (employee: any) => employee.year === last_year
    )?.numberOfEmployees;

    return (
      <>
        {/* @ts-ignore */}
        <Section title={t("company/common:daten_import.finance.headline")} ref={ref}>
          <Card>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ mb: 2 }}
            >
              <TextField
                name="sales_prev_year"
                label={
                  <Trans
                    i18nKey="company/common:daten_import.finance.sales_prev_year"
                    values={{ prev_year }}
                  />
                }
                InputProps={{
                  inputComponent: CurrencyFormatter as any,
                }}
                inputProps={{
                  currency: "EUR",
                  name: "sales_prev_year",
                  autoComplete: "sales_prev_year",
                  inputMode: "numeric",
                }}
                value={vale_prev_year || sales_prev_year}
                size="small"
                fullWidth
                onChange={(e) => handleSusaDataChange(e, "susa")}
                error={!!formErrors?.sales_prev_year?.message}
                helperText={
                  formErrors?.sales_prev_year
                    ? (formErrors?.sales_prev_year?.message as string)
                    : ""
                }
              />

              <TextField
                name="sales_last_year"
                label={
                  <Trans
                    i18nKey="company/common:daten_import.finance.sales_last_year"
                    values={{ last_year }}
                  />
                }
                InputProps={{
                  inputComponent: CurrencyFormatter as any,
                }}
                inputProps={{
                  currency: "EUR",
                  name: "sales_last_year",
                  autoComplete: "sales_last_year",
                  inputMode: "numeric",
                }}
                value={vale_last_year || sales_last_year}
                size="small"
                fullWidth
                onChange={(e) => handleSusaDataChange(e, "susa")}
                error={!!formErrors?.sales_last_year?.message}
                helperText={
                  formErrors?.sales_last_year
                    ? (formErrors?.sales_last_year?.message as string)
                    : ""
                }
              />
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ my: 2 }}
            >
              <TextField
                name="ebitda_prev_year"
                label={
                  <Trans
                    i18nKey="company/common:daten_import.finance.ebitda_prev_year"
                    values={{ prev_year }}
                  />
                }
                InputProps={{
                  inputComponent: CurrencyFormatter as any,
                }}
                inputProps={{
                  currency: "EUR",
                  name: "ebitda_prev_year",
                  autoComplete: "ebitda_prev_year",
                  inputMode: "numeric",
                  allowNegative: true,
                }}
                value={ebitda_prev_year}
                size="small"
                fullWidth
                onChange={(e) => handleSusaDataChange(e, "susa")}
                error={!!formErrors?.ebitda_prev_year?.message}
                helperText={
                  formErrors?.ebitda_prev_year
                    ? (formErrors?.ebitda_prev_year?.message as string)
                    : ""
                }
              />

              <TextField
                name="ebitda_last_year"
                label={
                  <Trans
                    i18nKey="company/common:daten_import.finance.ebitda_last_year"
                    values={{ last_year }}
                  />
                }
                InputProps={{
                  inputComponent: CurrencyFormatter as any,
                }}
                inputProps={{
                  currency: "EUR",
                  name: "ebitda_last_year",
                  autoComplete: "ebitda_last_year",
                  inputMode: "numeric",
                  allowNegative: true,
                }}
                value={ebitda_last_year}
                size="small"
                fullWidth
                onChange={(e) => handleSusaDataChange(e, "susa")}
                error={!!formErrors?.ebitda_last_year?.message}
                helperText={
                  formErrors?.ebitda_last_year
                    ? (formErrors?.ebitda_last_year?.message as string)
                    : ""
                }
              />
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ mb: 2 }}
            >
              <TextField
                name="ebit_prev_year"
                label={
                  <Trans
                    i18nKey="company/common:daten_import.finance.ebit_prev_year"
                    values={{ prev_year }}
                  />
                }
                InputProps={{
                  inputComponent: CurrencyFormatter as any,
                }}
                inputProps={{
                  currency: "EUR",
                  name: "ebit_prev_year",
                  autoComplete: "ebit_prev_year",
                  inputMode: "numeric",
                  allowNegative: true,
                }}
                value={ebit_prev_year}
                size="small"
                fullWidth
                onChange={(e) => handleSusaDataChange(e, "susa")}
                error={!!formErrors?.ebit_prev_year?.message}
                helperText={
                  formErrors?.ebit_prev_year ? (formErrors?.ebit_prev_year?.message as string) : ""
                }
              />

              <TextField
                name="ebit_last_year"
                label={
                  <Trans
                    i18nKey="company/common:daten_import.finance.ebit_last_year"
                    values={{ last_year }}
                  />
                }
                InputProps={{
                  inputComponent: CurrencyFormatter as any,
                }}
                inputProps={{
                  currency: "EUR",
                  name: "ebit_last_year",
                  autoComplete: "ebit_last_year",
                  inputMode: "numeric",
                  allowNegative: true,
                }}
                value={ebit_last_year}
                size="small"
                fullWidth
                onChange={(e) => handleSusaDataChange(e, "susa")}
                error={!!formErrors?.ebit_last_year?.message}
                helperText={
                  formErrors?.ebit_last_year ? (formErrors?.ebit_last_year?.message as string) : ""
                }
              />
            </Stack>

            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
              <TextField
                name="number_employees_prev_year"
                label={
                  <Trans
                    i18nKey="company/common:daten_import.finance.number_employees_prev_year"
                    values={{ prev_year }}
                  />
                }
                value={value_employees_prev_year || number_employees_prev_year}
                InputProps={{
                  inputComponent: CurrencyFormatter as any,
                }}
                inputProps={{
                  currency: "",
                  name: "number_employees_prev_year",
                  autoComplete: "number_employees_prev_year",
                  inputMode: "numeric",
                }}
                size="small"
                fullWidth
                onChange={(e) => handleSusaDataChange(e, "susa")}
                error={!!formErrors?.number_employees_prev_year?.message}
                helperText={
                  formErrors?.number_employees_prev_year
                    ? (formErrors?.number_employees_prev_year?.message as string)
                    : ""
                }
              />

              <TextField
                name="number_employees_last_year"
                label={
                  <Trans
                    i18nKey="company/common:daten_import.finance.number_employees_last_year"
                    values={{ last_year }}
                  />
                }
                value={value_employees_last_year || number_employees_last_year}
                InputProps={{
                  inputComponent: CurrencyFormatter as any,
                }}
                inputProps={{
                  currency: "",
                  name: "number_employees_last_year",
                  autoComplete: "number_employees_last_year",
                  inputMode: "numeric",
                }}
                size="small"
                fullWidth
                onChange={(e) => handleSusaDataChange(e, "susa")}
                error={!!formErrors?.number_employees_last_year?.message}
                helperText={
                  formErrors?.number_employees_last_year
                    ? (formErrors?.number_employees_last_year?.message as string)
                    : ""
                }
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="stretch"
              width="100%"
              sx={{ mt: 4 }}
            >
              <Ownerships
                ownerships={ownerships ? ownerships : []}
                onChange={handleOwnershipChange}
              />
            </Stack>
          </Card>
        </Section>
      </>
    );
  }
);
