import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from "react-table";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLawyers } from "../../elements/frontend/src/hooks/useLawyers";

import { fuzzyTextFilterFn, DefaultColumnFilter, GlobalFilter } from "./helper";

import {
  Wrapper,
  StyledTableCell,
  StyledTableRow,
  StyledTextField,
  StyledSelect,
  StyledAdvisorSelect,
} from "./styled.tableComp";
import { useTransactions } from "../../elements/frontend/src/hooks";
import { CustomTooltip, LoadingIndicator } from "../../elements/frontend/src/components";

import { DashboardDispatch, DashbordContext } from "../../views/Private/Home";

import { Card, Checkbox, FormControlLabel, Modal, Typography } from "@mui/material";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";

export const OverviewTable = ({
  columns,
  data,
  handleShowStatus = () => {},
  handleShowDetails = () => {},
  handleShowPreview = () => {},
  handleNewRegisteredCheckboxChange = () => {},
  onlyDetails = false,
  newRegister = false,
  loading = false,
  statusChange = false,
  handleSyncLocalStorage = () => {},
  handleSyncAllLocalStorage = () => {},
  allFieldsAreValid = () => {},
  testMode,
}) => {
  const lawyers = useLawyers();

  const { t } = useTranslation(["platform/common"]);

  const { updateTransaction, getAllTransactions } = useTransactions();

  const dashboardContext = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);
  const [response, setResponse] = useState();
  const [openWarning, setOpenWarning] = useState(undefined);
  const dispatch = useDispatch();

  const [transactionData, setTransactionData] = useState([]);
  // const [transaction, setTransaction] = useState();

  const getTransactions = async () => {
    const AllTransactions = await getAllTransactions();
    setResponse(AllTransactions);
  };

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // stepsObject to assign text as subheading for Treeview from locales
  const stepsObject = t("transaction_tables.process_flow.steps", {
    returnObjects: true,
  });

  // to assign text from locales for advisor in new registered customers
  const advisorObject = t("platform/common:content.overview.new_registered_customers.advisor", {
    returnObjects: true,
  });

  // to assign text from locales for investor in new registered customers
  const investorObject = t("platform/common:content.overview.new_registered_customers.investor", {
    returnObjects: true,
  });

  const advisorOptions = useMemo(() => {
    return dashboardContext.advisorOptions.filter((advisor) => {
      return advisor.name === "None"
        ? true
        : advisor.name.toLowerCase().includes("test" || "testing") === testMode;
    });
  }, [dashboardContext.advisorOptions, testMode]);

  const supervisorOptions = dashboardContext.supervisorOptions;

  const lawyerOptions = lawyers;

  const handleDetailsOnly = (id) => {
    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        dashboard_showStatus: true,
      },
    });
    handleShowDetails(id);
  };

  // update state transactionData with props data
  useEffect(() => {
    if (data && Array.isArray(data)) {
      setTransactionData(data);
    }
  }, [data]);

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: transactionData,
      initialState: { pageIndex: 0, pageSize: 10 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!  We need to place useFilters before useSortBy.
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  // to convert supervisor and advisor's name to id
  const convertNameToId = (name, user) => {
    let id;

    if (name === t("transaction_tables.none")) {
      id = "undefined";
      return id;
    }

    if (user === "assigned_supervisor") {
      const supervisor = supervisorOptions.find((item) => item.name === name);
      if (supervisor) {
        id = supervisor.user_id;
      } else {
        id = "undefined";
      }
    } else if (user === "assigned_advisor") {
      const advisor = advisorOptions.find((item) => item.name === name);
      if (advisor) {
        id = advisor.customer_id;
      } else {
        id = "undefined";
      }
    }
    return id;
  };

  const handleConfirmation = (e, rowIndex, columnId, transactionId) => {
    setOpenWarning({
      e: e,
      rowIndex: rowIndex,
      columnId: columnId,
      name: e.target.value,
      transactionId: transactionId,
    });
  };

  // supervisor, advisor select element change event handler
  const handleSelectChangeEvent = (e, rowIndex, columnId, transactionId) => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      const value = e.target.value;

      const transaction = transactionData.find((item) => item.transaction_id === transactionId);

      // it is either user_id or customer_id, according to columnId
      const id =
        columnId === "assigned_lawyer"
          ? lawyerOptions.find((lawyer) => lawyer.name === value)?.customer_id
          : convertNameToId(value, columnId);

      const objectToUpdate =
        columnId === "assigned_supervisor"
          ? {
              supervisor: {
                ...transaction.supervisor,
                user_id: id,
              },
              metadata: [
                {
                  scope: "state",
                  data: {
                    supervisor: id === "undefined" ? false : true,
                  },
                },
              ],
            }
          : columnId === "assigned_advisor"
          ? {
              advisor: {
                ...transaction.advisor,
                customer_id: id,
                user_id: "undefined",
              },
              metadata: [
                {
                  scope: "state",
                  data: {
                    advisor_matching: id === "undefined" ? false : true,
                    advisor_accepted: id === "undefined" ? false : false,
                  },
                },
              ],
            }
          : {
              lawyer: {
                ...transaction.lawyer,
                customer_id: id,
                user_id: "undefined",
              },
              metadata: [
                {
                  scope: "state",
                  data: {
                    lawyer_matching: id === "undefined" ? false : true,
                    lawyer_accepted: false,
                  },
                },
              ],
            };
      // update transaction in DB
      updateTransaction(transactionId, objectToUpdate).then((response) => {
        if (response) {
          // update in state transactionData
          setTransactionData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                };
              }
              window.location.reload();
              return row;
            })
          );
          dispatch(setCurrentUser({ loading: false }));
        }
      });
    } catch (e) {
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };
  // translation for warning window not in locales yet !!!
  const translations = {
    assigned_advisor: "Berater",
    assigned_lawyer: "Anwalt",
  };

  return (
    <Wrapper>
      {loading && <LoadingIndicator type={"COMPONENT"} />}
      {!loading && (
        <>
          {openWarning !== undefined && (
            <Modal
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              open={openWarning !== undefined}
              sx={{
                margin: "0px auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card sx={{ padding: "20px" }}>
                <Typography sx={{ fontSize: "20px", textAlign: "center", margin: "10px" }}>
                  Sind Sie sicher, dass Sie{" "}
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    {openWarning?.name}
                  </Typography>{" "}
                  als {translations[openWarning?.columnId] || openWarning?.columnId} zuweisen
                  möchten?
                </Typography>
                <Typography sx={{ fontSize: "20px", textAlign: "center" }}>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    {openWarning?.name}
                  </Typography>{" "}
                  wird eine E-Mail erhalten!
                </Typography>
                <Stack
                  sx={{ marginTop: "20px", flexDirection: "row", justifyContent: "space-evenly" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      padding: "10px 10px ",
                      width: "150px",
                    }}
                    onClick={() => {
                      handleSelectChangeEvent(
                        openWarning.e,
                        openWarning.rowIndex,
                        openWarning.columnId,
                        openWarning.transactionId
                      );
                      setOpenWarning(undefined);
                    }}
                  >
                    Zuweisen
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      padding: "10px 10px",
                      width: "150px",
                    }}
                    onClick={() => {
                      setOpenWarning(undefined);
                    }}
                  >
                    Abbrechen
                  </Button>
                </Stack>
              </Card>
            </Modal>
          )}
          <Table {...getTableProps()} size="small">
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? "   🔽" : "   🔼") : ""}
                      </span>
                      <div onClick={(e) => e.stopPropagation()}>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))}
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {t("transaction_tables.columns.action")}
                  </th>
                </TableRow>
              ))}
              <TableRow>
                <th
                  colSpan={statusChange ? visibleColumns.length : visibleColumns.length + 1}
                  style={{
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
                {statusChange && (
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <CustomTooltip title={t("misc.update_all")} placement="top" arrow={true}>
                      <IconButton
                        aria-label="sync"
                        color="primary"
                        onClick={() => handleSyncAllLocalStorage()}
                      >
                        <SyncIcon />
                      </IconButton>
                    </CustomTooltip>
                  </th>
                )}
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);

                const FilterdTransaction = response?.transactions?.filter(
                  (t) => t.transaction_id === row.original.transaction_id
                )[0];

                const steps = FilterdTransaction?.state?.steps;
                const advisor_matching = steps?.advisor_matching?.completed;
                const advisor_accepted = steps?.advisor_accepted?.completed;
                const contract_advisor_signed = steps?.contract_advisor_signed?.completed;
                const contract_company_signed = steps?.contract_company_signed?.completed;
                const lawyer_matching = steps?.lawyer_matching?.completed;
                const disableAdvisorAssign =
                  (advisor_matching && advisor_accepted && contract_advisor_signed) ||
                  !contract_company_signed;

                return (
                  <StyledTableRow {...row.getRowProps()}>
                    {supervisorOptions &&
                      advisorOptions &&
                      lawyerOptions &&
                      row.cells.map((cell) => {
                        if (cell.column.id === "assigned_supervisor") {
                          return (
                            <StyledTableCell {...cell.getCellProps()}>
                              <StyledSelect
                                size="small"
                                defaultValue={""}
                                value={(supervisorOptions && cell.value) || ""}
                                onChange={(e) =>
                                  handleConfirmation(
                                    e,
                                    cell.row.index,
                                    cell.column.id,
                                    row.original.transaction_id
                                  )
                                }
                                sx={{ minWidth: "100%" }}
                              >
                                {supervisorOptions &&
                                  supervisorOptions
                                    .map((item) => item.name)
                                    .map((menuItem, ind) => (
                                      <MenuItem key={ind} value={menuItem}>
                                        {menuItem}
                                      </MenuItem>
                                    ))}
                              </StyledSelect>
                            </StyledTableCell>
                          );
                        } else if (cell.column.id === "assigned_lawyer") {
                          const lawyerValue = lawyer_matching === false ? "None" : cell.value;
                          if (!cell.row.original.isLawyerClient) {
                            return (
                              <StyledTableCell {...cell.getCellProps()}>
                                <StyledSelect
                                  size="small"
                                  value={(lawyerOptions && lawyerValue) || ""}
                                  onChange={(e) =>
                                    handleConfirmation(
                                      e,
                                      cell.row.index,
                                      cell.column.id,
                                      row.original.transaction_id
                                    )
                                  }
                                  sx={{ minWidth: "100%" }}
                                >
                                  {lawyerOptions &&
                                    lawyerOptions
                                      .map((item) => item.name)
                                      .map((menuItem, ind) => (
                                        <MenuItem key={ind} value={menuItem}>
                                          {menuItem}
                                        </MenuItem>
                                      ))}
                                </StyledSelect>
                              </StyledTableCell>
                            );
                          }
                          return (
                            <StyledTableCell {...cell.getCellProps()}>
                              <StyledAdvisorSelect value={cell.value} disabled fullWidth />
                            </StyledTableCell>
                          );
                        } else if (cell.column.id === "assigned_advisor") {
                          const advisorValue = advisor_matching === false ? "None" : cell.value;
                          if (!cell.row.original.isAdvisorClient) {
                            return (
                              <StyledTableCell {...cell.getCellProps()}>
                                <StyledSelect
                                  size="small"
                                  value={(advisorOptions && advisorValue) || ""}
                                  onChange={(e) =>
                                    handleConfirmation(
                                      e,
                                      cell.row.index,
                                      cell.column.id,
                                      row.original.transaction_id
                                    )
                                  }
                                  disabled={disableAdvisorAssign}
                                  sx={{ minWidth: "100%" }}
                                >
                                  {advisorOptions &&
                                    advisorOptions
                                      .map((item) => item.name)
                                      .map((menuItem, ind) => (
                                        <MenuItem key={ind} value={menuItem}>
                                          {menuItem}
                                        </MenuItem>
                                      ))}
                                </StyledSelect>
                              </StyledTableCell>
                            );
                          }
                          return (
                            <StyledTableCell {...cell.getCellProps()}>
                              <StyledAdvisorSelect value={cell.value} disabled fullWidth />
                            </StyledTableCell>
                          );
                        }
                        return (
                          <StyledTableCell {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </StyledTableCell>
                        );
                      })}
                    <StyledTableCell
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        textAlign: newRegister ? "left" : "center",
                      }}
                    >
                      {newRegister && (
                        <FormControlLabel
                          margin="dense"
                          sx={{ paddingLeft: "10px" }}
                          disabled={
                            row.original.next_action === "Freigabe"
                              ? !allFieldsAreValid(row.original)
                              : false
                          }
                          control={
                            <Checkbox
                              checked={false}
                              onChange={(e) =>
                                handleNewRegisteredCheckboxChange(
                                  e,
                                  row.original.next_step,
                                  row.original.id,
                                  row.original.supervisor,
                                  row.original.newTransaction,
                                  row.original.transaction_id
                                )
                              }
                              size="small"
                              sx={{
                                "&.MuiCheckbox-root": {
                                  padding: "0 5px 0 0",
                                  fontSize: "12px",
                                },
                              }}
                            />
                          }
                          label={
                            row.original.type === "Advisor"
                              ? advisorObject[row.original.next_step]
                              : row.original.type === "Investor"
                              ? investorObject[row.original.next_step]
                              : stepsObject[row.original.next_step]
                          }
                        />
                      )}

                      {!newRegister ? (
                        <Box
                          sx={{ display: "flex", margin: "auto", justifyContent: "space-evenly" }}
                        >
                          {!onlyDetails && (
                            <CustomTooltip title={t("misc.status")} placement="top" arrow={true}>
                              <IconButton
                                size="small"
                                onClick={() => handleShowStatus(row.original.transaction_id)}
                                disabled={!row.original.transaction_id}
                                sx={{ fillOpacity: row.original.transaction_id ? "100%" : "20%" }}
                              >
                                <SummarizeOutlinedIcon color="primary" fontSize="small" />
                              </IconButton>
                            </CustomTooltip>
                          )}

                          <CustomTooltip title={t("misc.details")} placement="top" arrow={true}>
                            <IconButton
                              size="small"
                              onClick={() => handleShowDetails(row.original.transaction_id)}
                              disabled={!row.original.transaction_id}
                              sx={{ fillOpacity: row.original.transaction_id ? "100%" : "20%" }}
                            >
                              <ManageSearchIcon color="primary" fontSize="small" />
                            </IconButton>
                          </CustomTooltip>
                          {statusChange && (
                            <CustomTooltip title={t("misc.update")} placement="top" arrow={true}>
                              <IconButton
                                aria-label="sync"
                                color="primary"
                                onClick={() => handleSyncLocalStorage(row.original.transaction_id)}
                              >
                                <SyncIcon />
                              </IconButton>
                            </CustomTooltip>
                          )}
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex", justifySelf: "center" }}>
                          <CustomTooltip
                            title={
                              row.original.type === "Transaction"
                                ? t("misc.details")
                                : t("misc.preview")
                            }
                            placement="top"
                            arrow={true}
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                row.original.type === "Transaction"
                                  ? handleDetailsOnly(row.original.transaction_id)
                                  : handleShowPreview(row.original, i)
                              }
                              disabled={!row.original.id}
                              sx={{ fillOpacity: row.original.id ? "100%" : "20%" }}
                            >
                              {row.original.type === "Transaction" ? (
                                <ManageSearchIcon color="primary" fontSize="small" />
                              ) : (
                                <>
                                  <VisibilityIcon color="primary" fontSize="small" />
                                </>
                              )}
                            </IconButton>
                          </CustomTooltip>
                        </Box>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ marginTop: "10px" }}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                gotoPage(0);
              }}
              disabled={!canPreviousPage}
              sx={{ minWidth: "35px", padding: "5px" }}
            >
              {"<<"}
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                previousPage();
              }}
              disabled={!canPreviousPage}
              sx={{ minWidth: "35px", padding: "5px" }}
            >
              {"<"}
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                nextPage();
              }}
              disabled={!canNextPage}
              sx={{ minWidth: "35px", padding: "5px" }}
            >
              {">"}
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                gotoPage(pageCount - 1);
              }}
              disabled={!canNextPage}
              sx={{ minWidth: "35px", padding: "5px" }}
            >
              {">>"}
            </Button>

            <span style={{ fontSize: "15px" }}>
              {t("transaction_tables.pagination.page")}
              <strong>
                {pageIndex + 1} {t("transaction_tables.pagination.of")} {pageOptions.length}
              </strong>
            </span>
            <span style={{ fontSize: "15px" }}>
              |  {t("transaction_tables.pagination.go_to_page")}
            </span>
            <StyledTextField
              defaultValue={pageIndex + 1}
              type="number"
              onChange={(e) => {
                e.preventDefault();
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              sx={{ width: "70px" }}
            />
            <StyledSelect
              size="small"
              value={pageSize}
              onChange={(e) => {
                e.preventDefault();
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize, i) => (
                <MenuItem key={i} value={pageSize}>
                  {t("transaction_tables.pagination.show")}  {pageSize}
                </MenuItem>
              ))}
            </StyledSelect>
          </Stack>
        </>
      )}
    </Wrapper>
  );
};
