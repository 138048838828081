import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import TAwsConfig from "../../../../../awsConfig";
import { useAccounts } from "../../hooks";
import { initialState } from "./initialState";
import {
  Mapping,
  CategoryTree,
  Category,
  Country,
  Geo,
  Investor,
  FileType,
  Source,
  FileTypeTree,
  TransactionTree,
  TransactionStep,
  LegalForm,
  FiscalYear,
  MandantTree,
  UserRole,
  InvestorPeriod,
  CompanyStake,
  InvestorType,
  InvestorPhase,
  TransactionStructure,
  InvestorStake,
  Ebitda,
  EbitdaBracket,
  Invest,
  InvestBracket,
  Revenue,
  RevenueBracket,
  OwnerType,
  CompetitorType,
  priority,
} from "../api";

// to get account data
export const fetchAccountData = createAsyncThunk("currentUser/fetchAccountData", async () => {
  const { getAccount } = useAccounts();

  return await getAccount();
});

export const getFromDataStore = createAsyncThunk<
  [
    Mapping[],
    CategoryTree[],
    Category[],
    Country[],
    Geo[],
    Source[],
    Investor[],
    FileType[],
    FileTypeTree[],
    TransactionTree[],
    TransactionStep[],
    LegalForm[],
    FiscalYear[],
    MandantTree[],
    UserRole[],
    InvestorPeriod[],
    CompanyStake[],
    InvestorType[],
    InvestorPhase[],
    InvestorPhase[],
    TransactionStructure[],
    InvestorStake[],
    Ebitda[],
    EbitdaBracket[],
    Invest[],
    InvestBracket[],
    Revenue[],
    RevenueBracket[],
    OwnerType[],
    CompetitorType[],
    priority[]
  ]
>("currentUser/getFromDataStore", async () => {
  const response = await axios.all([
    axios.get(`${TAwsConfig.datastore.URL}/mappings.json`),
    axios(`${TAwsConfig.datastore.URL}/data/industry/categories.tree.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/industry/categories.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/account/countries.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/geo/iso3166 _a3_a2.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/marketing/sources.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/account/investor.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/files/types.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/files/types.tree.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/state/transaction.tree.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/state/transaction.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/account/legal_form.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/account/fiscal_year.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/state/mandant.tree.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/user/roles.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/period.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/company/stake.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/types.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/phases.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/phases.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/transaction/structure.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/stake.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/ebitda.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/ebitda_bracket.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/invest.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/invest_bracket.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/revenue.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/revenue_bracket.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/account/owner_type.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/company/competitorType.json`),
    axios.get(`${TAwsConfig.datastore.URL}/data/investor/priority.json`),
  ]);

  const [
    mappings,
    cats_tree,
    cats,
    countries,
    geo,
    sources,
    investor,
    fileTypes,
    fileTypeTree,
    transactionsTree,
    transactionSteps,
    legForm,
    fisYear,
    mandantTree,
    userRoles,
    investorPeriods,
    stakes,
    investorTypes,
    investorPhases,
    phases,
    transactionStructures,
    stake,
    ebitda,
    ebitda_bracket,
    invest,
    invest_bracket,
    revenue,
    revenue_bracket,
    owner_type,
    competitorType,
    priority,
  ] = response;
  return [
    mappings.data.links,
    cats_tree.data.data.tree,
    cats.data.data.items,
    countries.data.data.items,
    geo.data.data.items,
    sources.data.data.items,
    investor.data.data.items,
    fileTypes.data.data.items,
    fileTypeTree.data.data.tree,
    transactionsTree.data.data.tree,
    transactionSteps.data.data.items,
    legForm.data.data.items,
    fisYear.data.data.items,
    mandantTree.data.data.tree,
    userRoles.data.data.items,
    investorPeriods.data.data.items,
    stakes.data.data.items,
    investorTypes.data.data.items,
    investorPhases.data.data.items,
    phases.data.data.items,
    transactionStructures.data.data.items,
    stake.data.data.items,
    ebitda.data.data.items,
    ebitda_bracket.data.data.items,
    invest.data.data.items,
    invest_bracket.data.data.items,
    revenue.data.data.items,
    revenue_bracket.data.data.items,
    owner_type.data.data.items,
    competitorType.data.data.items,
    priority.data.data.items,
  ];
});

export const getMainContent = (approved: boolean | undefined, product_id: string): string => {
  const hasBeenApproved = !!approved;
  if (hasBeenApproved && product_id === "investor") {
    return "investor_content";
  }
  if (hasBeenApproved && product_id !== "investor") {
    return "advisor_content";
  }

  return "initial_content";
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearCurrentUser: (state, action) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAccountData.fulfilled, (state, { payload }) => {
      const getApproved = payload.customer?.metadata?.find((item: any) => item.scope === "state")
        ?.data?.approved;
      state.advisors = payload.advisors;
      state.customer = payload.customer;
      state.clients = payload.clients;
      state.user = payload.user;
      state.transactions = payload.transactions;
      state.current_main_content = getMainContent(
        payload.customer?.metadata?.find((item: any) => item.scope === "state")?.data?.approved,
        payload.customer.subscription.product_id
      );
      payload.customer.subscription.product_id === "investor"
        ? (state.investor_approved = getApproved)
        : (state.advisor_approved = getApproved);
      state.loading = false;
      state.state = "READY";
    });
    builder.addCase(fetchAccountData.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getFromDataStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFromDataStore.fulfilled, (state, { payload }) => {
      state.datastore.mappings = payload[0];
      state.datastore.categoriesTree = payload[1];
      state.datastore.categories = payload[2];
      state.datastore.countries = payload[3];
      state.datastore.region = payload[4];
      state.datastore.sources = payload[5];
      state.datastore.investor = payload[6];
      state.datastore.fileTypes = payload[7];
      state.datastore.fileTypesTree = payload[8];
      state.datastore.transactionTree = payload[9];
      state.datastore.transactionSteps = payload[10];
      state.datastore.legal_form = payload[11];
      state.datastore.fiscal_year = payload[12];
      state.datastore.mandantTree = payload[13];
      state.datastore.userRoles = payload[14];
      state.datastore.period = payload[15];
      state.datastore.companyStake = payload[16];
      state.datastore.types = payload[17];
      state.datastore.investorPhase = payload[18];
      state.datastore.phases = payload[19];
      state.datastore.transactionStructure = payload[20];
      state.datastore.stake = payload[21];
      state.datastore.ebitda = payload[22];
      state.datastore.ebitda_bracket = payload[23];
      state.datastore.invest = payload[24];
      state.datastore.invest_bracket = payload[25];
      state.datastore.revenue = payload[26];
      state.datastore.revenue_bracket = payload[27];
      state.datastore.owner_type = payload[28];
      state.datastore.competitorType = payload[29];
      state.datastore.priority = payload[30];
    });
    builder.addCase(getFromDataStore.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setCurrentUser, clearCurrentUser } = currentUserSlice.actions;
export default currentUserSlice.reducer;

/**
 * CUSTOMER STATE
 * "UNKNOWN",
 * "PENDING",
 * "ERROR",
 * "READY",
 */
