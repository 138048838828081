import { useState } from "react";
import { useAllInvestors } from "./useAllInvestors";
import { useInvitedInvestors } from "./useInvitedInvestors";
import { useParams } from "react-router-dom";

export default function useInvestorMatching() {
  const [transaction, setTransaction] = useState<any>(undefined);
  const params = useParams();
  const transactionId = params.transactionId;
  const [expandCategories, setExpandCategories] = useState<string>("");

  const { investors, loadingInvestors, addCustomInvestor } = useAllInvestors({
    transactionId,
    setTransaction,
    transaction,
  });

  const { invitations, loadingInvitations, isInvited, inviteInvestor, unInviteInveestor } =
    useInvitedInvestors({
      transactionId,
      investors,
      transaction,
    });

  return {
    inviteInvestor,
    investors,
    loadingInvestors,
    setExpandCategories,
    invitations,
    loadingInvitations,
    expandCategories,
    isInvited,
    addCustomInvestor,
    unInviteInveestor,
  };
}
