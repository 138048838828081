import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/system/Stack";
//import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { StatusProcessFlowTable } from "../../elements/frontend/src/components/StatusProcessFlowTable/StatusProcessFlowTable";

import {
  Section,
  Card,
  LoadingIndicator,
  //StatusDueDiligenceTable,
  DocumentsTable,
  StatusBaseInfoTable,
} from "../../elements/frontend/src/components";
import { SecondaryDialogSupervisor } from "../../elements/frontend/src/components/SecondaryDialogSupervisor/secondaryDialogSupervisor";

import { DashbordContext } from "../../views/Private/Home";
//import { TableWrapper } from "./styled.status";
import TConfig from "../../config";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../elements/frontend/src/Store/api/transactions/transactionsApi";
import { useParams } from "react-router-dom";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../elements/frontend/src/Store/hooks/useAppDispatch";
//import { SupervisorNotices } from "../../elements/frontend/src/components/SupervisorNotices/SupervisorNotices";
import { Messenger } from "../../elements/frontend/src/components/Messenger/Messenger";
import { ContactTable } from "../../elements/frontend/src/components/ContactTable/ContactTable";
//import { useAccounts } from "../../elements/frontend/src/hooks";

const Status = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["platform/common"]);
  const theme = useTheme();
  const params = useParams();
  const transactionId = params.transactionId;
  const dashboardContext = useContext(DashbordContext);
  const [secondaryDialogOpen, setSecondaryDialogOpen] = useState(false);
  const transactionByIdQuery = useGetTransactionByIdQuery({
    transactionId: transactionId,
  });
  const transaction = transactionByIdQuery.data;
  const isTransactionLoading = transactionByIdQuery.isLoading;

  // to get clientname in secondary Dialog Box
  const fileName = transaction?.customer_name;

  const [updateTransaction] = useUpdateTransactionMutation();
  //const { deleteAccount } = useAccounts();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateTransactionState = async (newTransaction) => {
    await updateTransaction({
      transactionId: newTransaction.transaction_id,
      body: newTransaction,
    });
    dispatch(setCurrentUser({ loading: false }));
  };

  // to show secondary delete dialog
  const handleSecondaryDeleteDialogOpen = () => {
    //setFileId(fileID);
    setSecondaryDialogOpen(true);
  };
  // to close secondary delete dialog
  const handleSecondaryDeleteDialogClose = () => {
    //setFileId(undefined);
    setSecondaryDialogOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: theme.breakpoints.values.xl,
      }}
    >
      <Section title={t("platform/common:content.status.headline")}>
        <Card>
          {isTransactionLoading && <LoadingIndicator type={"COMPONENT"} />}
          {!isTransactionLoading && (
            <>
              {/* 1st Box */}
              <Box
                sx={{
                  boxShadow: `2px 2px 4px ${theme.palette.grey[300]}`,
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: "5px",
                  padding: "15px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between" // Use this to push items to the ends
                  alignItems="center"
                  sx={{ mb: 2 }}
                >
                  <Typography
                    variant="h5"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "1rem", md: "1.3rem" },
                      color: theme.palette.info.dark,
                    }}
                  >
                    {transaction?.client_name}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {" "}
                    {/* Create a nested Stack for the buttons */}
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ fontWeight: "bold", fontSize: "13px", padding: "5px 10px" }}
                      onClick={handleSecondaryDeleteDialogOpen}
                    >
                      {t("Konto löschen")}
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      href={
                        TConfig.shared_links.PIPEDRIVE_URL +
                        transaction?.client_contact?.pipedrive_deal_id
                      }
                      target="_blank"
                      rel="noreferrer"
                      disabled={!transaction?.client_contact?.pipedrive_deal_id}
                      endIcon={<SendIcon fontSize="small" />}
                      sx={{ fontWeight: "bold", fontSize: "13px", padding: "5px 10px" }}
                    >
                      {t("transaction_tables.pipedrive")}
                    </Button>
                  </Stack>
                </Stack>

                <Grid container>
                  <Grid item xs={12} lg={6} maxWidth={"70%"}>
                    <Stack
                      sx={{
                        margin: { xs: "0 0 10px 0", lg: "0 10px 0 0" },
                      }}
                    >
                      {/* Company Contact Table */}
                      <ContactTable contactData={transaction?.client_contact} />

                      {/* Due Diligence Table */}
                      <StatusBaseInfoTable
                        transactionId={transaction.transaction_id}
                        updateTransactionState={updateTransactionState}
                      />
                      {/*  <StatusDueDiligenceTable /> */}
                      {/* Stammdaten Table */}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6} maxWidth={"20rem"}>
                    <Stack
                      sx={{ margin: { xs: "0 0 0 0", lg: "0 0 0 10px" }, height: "100%" }}
                      alignItems="stretch"
                    >
                      {/* Process Flow Table and Component */}
                      <StatusProcessFlowTable
                        transaction={transaction}
                        updateTransactionState={updateTransactionState}
                        registered={dashboardContext?.registered}
                        registeredLoading={dashboardContext?.registeredLoading}
                      />
                    </Stack>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={6} maxWidth={"100%"}>
                  <Stack
                    sx={{ margin: { xs: "0 0 0 0", lg: "0 0 0 10px" }, height: "100%" }}
                    alignItems="stretch"
                  >
                    <Box
                      sx={{
                        marginTop: "20px",
                        flex: 1,
                      }}
                    >
                      <Messenger transactionId={transactionId} productId="advisor" />
                    </Box>
                  </Stack>
                </Grid>
                {/* Deal Documents Table */}

                <DocumentsTable
                  originalFiles={transaction?.files ? [...transaction?.files] : []}
                  product={"platform"}
                  page={"status"}
                />
              </Box>
              {/* Secondary Dialog for customer email change confirmation */}
              <SecondaryDialogSupervisor
                type="warning"
                dialogTitle={t("platform/common:transaction_tables.deal_documents.delete_file")}
                contentText={
                  <Trans
                    i18nKey="transaction_tables.deal_documents.delete_file_dialog_content_text"
                    values={{ fileName }}
                  />
                }
                secondaryDialogOpen={secondaryDialogOpen}
                secondaryDialogClose={handleSecondaryDeleteDialogClose}
                //eventHandler={() => handleDeleteAccount}
                actionButtonText={t("platform/common:misc.delete")}
              />

              {/* 2nd Box */}

              {/*  <Box
                sx={{
                  boxShadow: `2px 2px 4px ${theme.palette.grey[300]}`,
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: "5px",
                  padding: "15px",
                  marginTop: "30px",
                }}
              >
                <Box
                  sx={{
                    border: "2px solid red",
                  }}
                >
                  <TableWrapper>
                    <thead>
                      <tr>
                        <th>Investor</th>
                        <th>Quelle</th>
                        <th>NDA</th>
                        <th>Factbook erhalten</th>
                        <th>Verträge erhalten</th>
                        <th>Signing</th>
                        <th>Closing</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ABC Private Equity LLP</td>
                        <td>BB / Berater</td>
                        <td>ja - 01.02.2023</td>
                        <td>ja - 05.02.2023</td>
                        <td>ja - 08.02.2023</td>
                        <td>ja - 15.02.2023</td>
                        <td>ja - 20.02.2023</td>
                      </tr>
                    </tbody>
                  </TableWrapper>
                </Box>
              </Box> */}
            </>
          )}
        </Card>
      </Section>
    </Box>
  );
};

export default Status;
