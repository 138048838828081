import { Card, Section } from "../../..";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PrimaryButton } from "../../..";
import { useAppDispatch } from "../../../../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../../../../Store/currentUser/currentUserSlice";
import { useTransactions } from "../../../../hooks";
import { FinancialMetrics } from "./financialMetrics";
import { LoadingIndicator } from "../../..";
import { Competitors } from "./Competitors";
import { CompetitorInput } from "./CompetitorInput";
import { useAppSelector } from "../../../../Store/hooks/useAppSelector";

const useCompetitors = (transactionId) => {
  const { getTransactionById } = useTransactions();
  const [compatitors, setCompatitors] = useState([]);
  const [loadingCompetitors, setLoadingCompetitors] = useState(true);

  const updateCompatitors = async () => {
    const fetch = (await getTransactionById(transactionId)).transaction?.metadata?.find(
      (item) => item.scope === "dd_commercial"
    )?.data?.compatitors;

    if (fetch) {
      setCompatitors(fetch);
      setLoadingCompetitors(false);
    }
  };
  useEffect(() => {
    updateCompatitors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [compatitors, updateCompatitors, setCompatitors, loadingCompetitors];
};

const useFinantialInformation = (transactionId) => {
  const [loadingFinancial, setLoadingFinancial] = useState(true);
  const { getTransactionById } = useTransactions();
  const [financialinformation, setFinancialinformation] = useState(undefined);

  const updateFinantialInformation = async () => {
    const fetchFinancialInformation = (
      await getTransactionById(transactionId)
    ).transaction?.metadata?.find((item) => item.scope === "dd_commercial")?.data
      ?.financialinformation;
    setFinancialinformation(fetchFinancialInformation);
    setLoadingFinancial(false);
  };

  useEffect(() => {
    updateFinantialInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    updateFinantialInformation,
    financialinformation,
    setFinancialinformation,
    loadingFinancial,
  ];
};

export const Commercial = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["company/due_dilligence"]);
  const { updateTransaction } = useTransactions();
  const theme = useTheme();
  const params = useParams();
  const transactionId = params.transactionId;
  const [compatitors, updateCompatitors, setCompatitors, loadingCompetitors] =
    useCompetitors(transactionId);
  const [
    updateFinantialInformation,
    financialinformation,
    setFinancialinformation,
    loadingFinancial,
  ] = useFinantialInformation(transactionId);

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const NotEditable = currentUser?.customer?.subscription.product_id === "company" ? true : false;

  const [intialData, setIntialData] = useState({
    id: uuidv4(),
    company_name: "",
    country: "",
    website: "",
    comment: "",
    type: "",
  });

  const handleDataChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setIntialData({
      ...intialData,
      [field]: value,
    });
  };

  const handleDeleteCompany = (id) => {
    setCompatitors((prev) => prev.filter((item) => item.id !== id));
  };

  const handleEditUser = (id) => {
    const foundCompany = compatitors.find((item) => item.id === id);
    if (foundCompany) {
      const { id, company_name, country, website, comment, type } = foundCompany;
      setIntialData({
        id,
        company_name,
        country,
        website,
        comment,
        type,
      });
      handleDeleteCompany(id);
    }
  };

  const handleAddUser = () => {
    setCompatitors((prev) => [...prev, { ...intialData }]);
    setIntialData({
      id: uuidv4(),
      company_name: "",
      country: "",
      website: "",
      comment: "",
      type: "",
    });
  };

  const handleSubmit = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      const objToUpdate = {
        metadata: [
          {
            scope: "dd_commercial",
            data: {
              compatitors: compatitors,
              financialinformation: financialinformation,
            },
          },
        ],
      };

      const response = await updateTransaction(transactionId, objToUpdate);
      updateCompatitors();
      updateFinantialInformation();

      if (response) {
        setIntialData({
          id: uuidv4(),
          company_name: "",
          country: "",
          website: "",
          comment: "",
          type: "",
        });
      }
      dispatch(setCurrentUser({ loading: false }));
    } catch (e) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  return (
    <>
      {loadingFinancial && loadingCompetitors && <LoadingIndicator type={"PROGRESS"} />}
      {!(loadingFinancial && loadingFinancial) && (
        <Box sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl }}>
          <Section>
            <Card>
              <Stack direction="column">
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme.palette.info.dark,
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  {t(`company/due_dilligence:dd_commercial.add_competitor`)}
                </Typography>
                <CompetitorInput
                  handleDataChange={handleDataChange}
                  intialData={intialData}
                  handleAddUser={handleAddUser}
                  NotEditable={NotEditable}
                />
                <Competitors
                  compatitors={compatitors}
                  handleDeleteCompany={handleDeleteCompany}
                  handleEditUser={handleEditUser}
                  NotEditable={NotEditable}
                />
              </Stack>
            </Card>
          </Section>
          <FinancialMetrics
            financialinformation={financialinformation}
            setFinancialinformation={setFinancialinformation}
            NotEditable={NotEditable}
          />
          {!NotEditable && (
            <Stack direction="row" justifyContent="flex-end">
              <PrimaryButton onClick={handleSubmit} title={t("misc.save")} />
            </Stack>
          )}
        </Box>
      )}
    </>
  );
};
