//import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
//import { useTranslation } from "react-i18next";
import { useCustomers, useInvestors, useTransactions } from "../../elements/frontend/src/hooks";
import { InvestorProps } from "../../elements/frontend/src/Store/interfaces";
import { useState, useRef, useCallback } from "react";
import { useGetCategoriesQuery } from "../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";

export function useAllInvestors({ transactionId, setTransaction, transaction }: any) {
  const { getInvestors, addInvestor } = useInvestors();
  const { getCustomerById } = useCustomers();
  const { getTransactionById } = useTransactions();

  const [investors, setInvestors] = useState<InvestorProps[] | undefined>(undefined);
  const [loadingInvestors, setLoadingInvestors] = useState<boolean>(true);
  const categoriesQuery = useGetCategoriesQuery();
  const categories = categoriesQuery.data;

  const customerCache = useRef<Map<string, any>>(new Map());
  const transactionCache = useRef<Map<string, any>>(new Map());
  const fetchingTransaction = useRef<boolean>(false);
  const hasFetched = useRef<boolean>(false);

  const fetchTransaction = useCallback(async () => {
    if (!transaction && !fetchingTransaction.current) {
      fetchingTransaction.current = true;
      try {
        const trans = await getTransactionById(transactionId);
        setTransaction(trans.transaction);
        transactionCache.current.set(transactionId, trans.transaction);
      } finally {
        fetchingTransaction.current = false;
      }
    }
  }, [transactionId, transaction, getTransactionById, setTransaction]);

  const mapInvestorsData = useCallback((investors: any[], customerCache: Map<string, any>) => {
    return investors.map((investor: any) => {
      const customer = customerCache.get(investor.customer_id);
      const investment = customer.metadata.find((meta: any) => meta.scope === "investment")?.data;

      return {
        id: customer.customer_id,
        name: customer.name,
        email: customer.email,
        ebitda: investment?.ebitda || "",
        invest: investment?.invest ? String(investment.invest) : "", // Ensure invest is a string
        revenue: investment?.revenue || "",
        categories: investment?.categories || [],
        sub_categories: investment?.sub_categories || [],
        period: investment?.period || [],
        phases: investment?.phases || [],
        types: investment?.types || [],
        stake: investment?.stake || [],
        contribution: investment?.contribution || {
          financial_investor: false,
          strategic_investor: false,
          wachstums_investor: false,
        },
        website: customer.contact?.website || "",
        line_1: customer.address?.line_1 || "",
        postal: customer.address?.postal || "",
        city: customer.address?.city || "",
        country: customer.address?.country || "",
      };
    });
  }, []);

  const fetchInvestors = useCallback(async () => {
    if (!investors && categories?.length && transaction && !hasFetched.current) {
      setLoadingInvestors(true);
      hasFetched.current = true;

      const resp = await getInvestors();
      const customerIds: string[] = Array.from(
        new Set(resp.investors.map((investor: any) => investor.customer_id))
      );

      const uncachedCustomerIds = customerIds.filter(
        (id: string) => !customerCache.current.has(id)
      );
      //console.log("uncachedCustomerIds", uncachedCustomerIds);

      if (uncachedCustomerIds.length > 0) {
        await Promise.all(
          uncachedCustomerIds.map((id: string) =>
            getCustomerById(id).then((data) => {
              const customer = data.customer;
              customerCache.current.set(id, customer);
              return customer;
            })
          )
        );
      }

      const investorsData = mapInvestorsData(resp.investors, customerCache.current);
      setInvestors(investorsData);
      setLoadingInvestors(false);
    }
  }, [investors, categories, transaction, getInvestors, getCustomerById, mapInvestorsData]);

  fetchTransaction();
  fetchInvestors();

  const addCustomInvestor = (values: any) => {
    const newInvestor = {
      name: values.name,
      email: values.email,
      address: {
        line_1: values.line_1,
        line_2: "",
        city: values.city,
        postal: values.postal,
        country: values.country,
      },
      contact: {
        first_name: "",
        last_name: "",
        telephone: "",
        website: values.website,
      },
      metadata: [
        {
          scope: "investment",
          data: {
            categories: values.categories,
            sub_categories: values.sub_categories,
            phases: values.phases,
            stake: values.stake,
            period: values.period,
            types: values.types,
            invest: values.invest,
            revenue: values.revenue,
            ebitda: values.ebitda,
            contribution: values.contribution,
          },
        },
      ],
    };
    addInvestor(newInvestor);
  };

  return { investors, loadingInvestors, addCustomInvestor };
}
