import { API } from "aws-amplify";
import { getCovid } from "../common";
import TConfig from "../../../../config";

export const useInvestors = () => {
  const getInvestors = () => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.INVESTOR_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };
  const addInvestor = (data) => {
    return API.post(TConfig.webapp.APP_API_ID, `${TConfig.webapp.INVESTOR_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
      body: data,
    });
  };

  return {
    getInvestors,
    addInvestor,
  };
};
