// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint no-template-curly-in-string: 0*/
const config = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "app-be-platform-844375376115-uploads",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://andestwixa.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_KaeZZhk28",
    APP_CLIENT_ID: "41j8nidgq24djsnaap5bagfrvt",
    IDENTITY_POOL_ID: "eu-central-1:e8e67a03-f8bb-4b63-8850-38541988ca27",
  },
  locales: {
    URL: "https://locales.platform.prod.beteiligungsboerse.net",
  },
  datastore: {
    URL: "https://datastore.platform.prod.beteiligungsboerse.net",
  },
  registration: {
    URL: "https://registration.platform.prod.beteiligungsboerse.net/v1",
    NEXT_PAGE: "https://app.platform.prod.beteiligungsboerse.net",
    TO_REFERRER: "https://app.platform.prod.beteiligungsboerse.net",
  },
  product: {
    PRODUCT_KEY: "platform",
  },
};

export default config;
